html {
    body {
        background: color('terniary') !important;
        color: color('white');
    }

    .header-container {
        .header-nav-top {
            background: color('primary');

            .contact-info {
                i {
                    color: #FFF;
                }
            }
        }

        .header-nav-main {
            background: color('secondary') !important;

            .company-brand {
                img {
                    margin-top: 20px;
                    margin-bottom: 20px;
                    height: 44px;
                }
            }

            .header-nav {
                font-weight: 400;
                text-transform: uppercase;
            }

            &>.container-fluid>.row {
                align-items: center;
            }
        }

        &.scrolled {
            .btn-menu {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    #homepage {
        .header-slider-wrapper {
            .header-slider-item {
                height: calc(100vh - 125px);
            }
        }
    }

    .header-slider-content {
        .header-slider-title {
            font-weight: 400;
        }
    }

    @media (max-width: 1023px) {
        .search-wrapper {
            .main-header {
                div.header-slider-item {
                    display: flex;
                    height: auto !important;
                    min-height: 200px;
                    aspect-ratio: 2/1;
                }

                .scroll-icon {
                    display: none;
                }
            }
        }
    }

    .mobile-search-wrapper {
        .search-box [class*="col-"] {
            border: none;
        }

        input {
            padding-left: 1rem;
        }

        input::placeholder, .placeholder {
            color: black !important;
        }

        .search-box {
            .action-options-wrapper {
                background: none;
                gap: 15px;

                .action-option {
                    color: black;
                    background-color: color('secondary');

                    label::after {
                        background: black !important;
                        height: 2px !important;
                    }
                }
            }
        }

        .show-detail-search {
            i {
                color: white;
            }
        }

        button[type="submit"] {
            padding: 0.8rem 0;
        }
    }

    .section {
        h1,
        h2,
        p,
        .section-text,
        .section-title {
            color: #e2e2e2;
        }
    }

    .property-card {
        background: color('white');
    }

    .main-footer {
        .footer-section {
            &.footer-bottom {
                background: color('primary');
            }
        }

        .social-media {
            @media (max-width: 991px) {
                display: flex;
                justify-content: center;
            }
        }
    }

    .property-intro {
        ul {
            color: color('white');
        }

        p {
            color: color('white');
            font-weight: 300;

            &.price {
                color: color('white');
            }
        }
    }

    .pdf-download {
        color: color('white');
    }

    .property-section {
        border-color: #423e3e;

        &.bg {
            background: #2d2828;
        }

        h2 {
            color: color('white');
        }

        .property-description {
            p,
            ul {
                color: color('white');
            }
        }

        .contact-form {
            input,
            textarea {
                background: #2d2828;
                color: color('white');
                border: 1px solid #423e3e;

                &::placeholder {
                    color: #777;
                }
            }
        }
    }

    .agents .agent-box ul {
        width: 90%;
    }

    .contact-card {
        color: color('white');

        .accent {
            color: color('white') !important;
        }
    }

    #contact_page {
        form {
            input,
            textarea {
                background: #2d2828;
                color: color('white');
                border: 1px solid #423e3e;

                &::placeholder {
                    color: #777;
                }
            }

            .contact-btn:hover {
                color: color('white');
            }
        }
    }

    .SumoSelect > .CaptionCont > span {
        color: #000;
    }

    body:not(#homepage) {
        .group-search.v4 {
            .select-column {
                border-right: 1px solid #8d8d8d;
            }

            .first-col {
                border-right: 1px solid #8d8d8d;

                input {
                    padding-left: 15px;
                }
            }

            .group-grid-action {
                width: 175px;
            }

            &.search-selects {
                color: #000;

                .select-column {
                    width: 251px !important;
                    background: #FFF;
                }
            }
        }
    }
}

body.sk {
    .homepage-search {
        .col-county {
            flex: 1 1;
        }

        .input-group> div:nth-of-type(7) {
            min-width: 150px;
        }
    }

    #gdpr-main {
        .text-column {
            width: 55%;
        }

        .button-column {
            width: 45%;
        }
    }
}

body.termsAndConditions {
    div#content {
        .section a {
            color: white !important;
        }
    }
}